import React from "react";
import Layout from "../../components/general/Layout";
import Magnet from "../../components/experiences/magnet";
import { Helmet } from "react-helmet";
import {
    EXPERIENCES,
    markExperienceAsVisited
} from "../../components/general/store";

const MagnetExperience = () => {
    markExperienceAsVisited(EXPERIENCES.MAGNET.key);
    return (
        <Layout>
            <Helmet>
                <script src="/scripts/aframe-ar.js"></script>
            </Helmet>
            <Magnet />
        </Layout>
    );
};

export default MagnetExperience;
