const EXPERIENCES_KEY = "experiences";

const localStorage = typeof window !== `undefined` ? window.localStorage : null;

const EXPERIENCES = {
    SOLAR_SYSTEM: {
        key: 1,
        name: "Solar System",
        route: "/experience-pages/solarsystem"
    },
    MAGNET: {
        key: 2,
        name: "Magnet",
        route: "/experience-pages/magnet"
    },
    ENGINE_ROOM: {
        key: 3,
        name: "Engine Room - Example",
        route: "/examples/engine-room"
    },
    DYNAMIC_LIGHTS: {
        key: 4,
        name: "Dynamic Lights - Example",
        route: "/examples/dynamic-lights"
    },
    WAVES_AND_RAIN: {
        key: 5,
        name: "Waves and Rain - Example",
        route: "/examples/waves-and-rain"
    }
};

function getExperiences() {
    if (localStorage) {
        return (
            JSON.parse(localStorage.getItem(EXPERIENCES_KEY)) ||
            Object.values(EXPERIENCES)
        );
    }
    return Object.values(EXPERIENCES);
}

function markExperienceAsVisited(key) {
    const experiences = getExperiences();
    const experience = experiences.find(exp => exp.key === key);
    experience.visited = true;
    if (localStorage) {
        localStorage.setItem(EXPERIENCES_KEY, JSON.stringify(experiences));
    }
}

export { EXPERIENCES, getExperiences, markExperienceAsVisited };
