import React, { useEffect, useState } from "react";
import Loader from "../../general/Loader";

const Magnet = props => {
    const [isInit, setIsInit] = useState(null);
    useEffect(() => {
        if (isInit === null) {
            setTimeout(() => setIsInit(true), 1000);
        }
    });
    if (isInit === null) {
        return <Loader />;
    }

    return (
        <div>
            <a-scene
                vr-mode-ui="enabled: false"
                renderer="colorManagement: true;"
                arjs="patternRatio: 0.90"
            >
                <a-marker
                    id="marker"
                    type="pattern"
                    preset="custom"
                    url="/markers/science-center-logo.patt"
                >
                    <a-entity
                        changepole
                        emitevents="true"
                        cursor="rayOrigin: mouse"
                    >
                        <a-entity position="-0.75 0 0">
                            <a-box
                                color="blue"
                                scale="0.5 0.25 0.25"
                                position="0.5 0 0"
                            ></a-box>
                            <a-box color="red" scale="0.5 0.25 0.25"></a-box>
                        </a-entity>

                        <a-entity id="magnet" position="2 0 0">
                            <a-box
                                id="southPole"
                                color="red"
                                position="-0.5 0 0"
                                scale="0.5 0.25 0.25"
                            ></a-box>
                            <a-box
                                id="northPole"
                                color="blue"
                                scale="0.5 0.25 0.25"
                            ></a-box>
                        </a-entity>
                    </a-entity>
                </a-marker>

                <a-entity camera></a-entity>
            </a-scene>
        </div>
    );
};

export default Magnet;
